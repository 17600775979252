html,
body,
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
}

:root {
  --default-border-radius: clamp(1.1rem, -0.5769rem + 3.0769vw, 1.5rem);
  --default-background-color: #f1f1f1;
  --brand-primary-color: #86004d;
  --card-width: clamp(9rem, -0.8654rem + 34.6154vw, 18.5rem);
  --spacer: clamp(0.8rem, -3.8908rem + 13.9738vw, 1.5rem);
}

.ifdigital-react-pwa {
  position: relative;
  overflow: hidden;
  background: transparent
    linear-gradient(180deg, #86004d 0%, #5d0036 81%, #430027 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  height: 100vh;
  max-height: 100%;
  min-height: 100%;
}

#logo{
  position: absolute;
  top: calc(var(--spacer) * 2);
  left: 50%;
  transform: translateX(-50%);
  height: 75px;
  width: 125px;
  background-image: url('img/misc/riso_logo.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
}

/**
* risoFarbfinder
**/

.risoFarbfinder {
  position: relative;
  overflow-y: auto;
  height: 100vh;
  background-image: url('img/misc/logo.png');
  background-repeat: no-repeat;
  background-size: 40vh;
  background-position: calc( 100% + 20px) -20px;
}

.risoFarbfinder button.back {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0);
  padding: calc(var(--spacer) / 2) calc(var(--spacer) * 2);
  border: 0;
  color: #fff;
  text-transform: uppercase;
  height: 50px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 999;
  backdrop-filter: blur(10px);
}

@media (min-width: 1025px) {
  .risoFarbfinder button.back {
    top: 25px;
    left: 25px;
    border-radius: var(--default-border-radius);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.risoFarbfinder button.back:before {
  content: "⬅︎ ";
  font-size: 110%;
}

/**
* navigation-fixed
**/
.risoFarbfinder--navigation-fixed {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  width: 100vw;
  min-width: 100%;
  font-size: clamp(0.8rem, -0.9937rem + 4.8035vw, 1.375rem);
  backdrop-filter: blur(10px);
}

@media (min-width: 1025px) {
  .risoFarbfinder--navigation-fixed {
    bottom: calc(var(--spacer) * 2);
    max-width: 800px;
    min-width: 800px;
    width: 800px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 2px solid rgba(255, 255, 255, 0.1);
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    border-right: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: var(--default-border-radius);
    overflow: hidden;
    background-color: var(--brand-primary-color);
  }
}

.risoFarbfinder--navigation-fixed label {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  position: relative;
  padding-inline: 0;
  cursor: pointer;
  width: 33.33%;
}

.risoFarbfinder--navigation-fixed input {
  display: none;
}

.risoFarbfinder--navigation-fixed
  input:checked
  ~ .risoFarbfinder--navigation-icon {
  border-top: 1px solid #fff;
}

.risoFarbfinder--navigation-icon {
  display: block;
  width: 100%;
  height: 40px;
  background-image: url("img/icons/icon--lashes.png");
  background-size: auto 80%;
  background-position: center center;
  background-color: transparent;
  background-repeat: no-repeat;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.risoFarbfinder--navigation-label {
  display: block;
  font-size: clamp(0.8rem, -0.9937rem + 4.8035vw, 1.375rem);
  font-size: 11px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.risoFarbfinder--navigation-icon.Lieder {
  background-image: url("img/icons/icon--lashes.png");
}

.risoFarbfinder--navigation-icon.Augenbrauen {
  background-image: url("img/icons/icon--brows.png");
}

.risoFarbfinder--navigation-icon.Lippen {
  background-image: url("img/icons/icon--lips.png");
}

/**
* slider 
**/
.risoFarbfinder--slider {
  display: flex;
  flex-wrap: nowrap;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  width: 100vw;
  gap: var(--spacer);
  overflow-y: hidden;
  overflow-x: scroll;
  min-width: 100vw;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/**
* card 
**/
.risoFarbfinder--card {
  display: block;
  overflow: hidden;
  position: relative;
  width: var(--card-width);
  min-width: 200px;
  border-radius: var(--default-border-radius);
  background-color: var(--default-background-color);
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: var(--spacer);
  margin-bottom: var(--spacer);
  cursor: pointer;
  aspect-ratio: 2 / 3;
  scroll-snap-align: center;
}

@media (min-width: 600px) {
  .risoFarbfinder--card {
    min-width: 280px;
  }
}

@media (min-width: 700px) {

  .risoFarbfinder--card {
    min-width: 360px;
  }
    
}

@media (min-width: 1024px) {
  .risoFarbfinder--card {
    min-width: 280px;
  }
}

.risoFarbfinder--card:first-child {
  margin-left: calc(var(--spacer) * 2);
}

.risoFarbfinder--card:last-child {
  margin-right: calc(var(--spacer) * 2);
}

.risoFarbfinder--card-figure {
  display: block;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  aspect-ratio: 4 / 6;
}

.risoFarbfinder--card-figure img {
  display: block;
  object-fit: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.risoFarbfinder--header {
  border-radius: var(--default-border-radius);
  background-color: var(--brand-primary-color);
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.risoFarbfinder--header * {
  font-size: clamp(0.8rem, -0.9937rem + 4.8035vw, 1.375rem);
  margin-top: 0;
  margin-left: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: var(--spacer);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
}

.risoFarbfinder--card input {
  display: none;
}

/**
* single product
**/

.risoFarbfinder--single-product {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 200px;
}

.risoFarbfinder--single-product header h2 {
  padding: calc(var(--spacer) * 2);
}

/**
* single product
* gallery
**/

.risoFarbfinder--single-product-gallery {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: var(--spacer);
  padding-bottom: var(--spacer);
}

.risoFarbfinder--single-product-gallery img {
  width: auto;
  height: 30vh;
}

/**
* single product
* details 
**/
.risoFarbfinder--single-product-details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: calc(var(--spacer) * 2);
  font-size: 110%;
  line-height: 1.4;
}

@media (min-width: 600px) {
  .risoFarbfinder--single-product-details {
    gap: var(--spacer);
  }
}

.risoFarbfinder--single-product-details > p {
  margin-bottom: calc(var(--spacer) * 2);
}

@media (min-width: 600px) {
  .risoFarbfinder--single-product-details > p {
    font-size: 120%;
    line-height: 1.6;
    hyphens: auto;
  }
  .risoFarbfinder--single-product-details > * {
    width: calc(50% - 0.5 * var(--spacer));
  }
}

.risoFarbfinder--single-product-details dl dt {
  position: relative;
  padding-left: calc(var(--spacer) * 4.5);
}

@media (min-width: 600px) {
  .risoFarbfinder--single-product-details dl dt {
    padding-left: calc(var(--spacer) * 2.5);
  }
}

.risoFarbfinder--single-product-details dl dt::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(var(--spacer) * 4);
  aspect-ratio: 1 / 1;
  background-color: transparent;
}

@media (min-width: 600px) {
  .risoFarbfinder--single-product-details dl dt::after {
    width: calc(var(--spacer) * 2);
  }
}

.risoFarbfinder--single-product-details dl dd {
  font-size: 80%;
  padding-left: calc(var(--spacer) * 4.5);
}

@media (min-width: 600px) {
  .risoFarbfinder--single-product-details dl dd {
    padding-left: calc(var(--spacer) * 2.5);
  }
}

.risoFarbfinder--single-product-details dl dt.usage:after {
  background-image: url("./img/icons/icon--usage.svg");
}

.risoFarbfinder--single-product-details dl dt.mixture:after {
  background-image: url("./img/icons/icon--mixture.svg");
}

.risoFarbfinder--single-product-details dl dt.warning:after {
  background-image: url("./img/icons/icon--warning.svg");
}

/**
* single product 
* call to action
**/

.risoFarbfinder--single-product .btn {
  display: block;
  text-align: center;
  font-size: clamp(0.8rem, -0.9937rem + 4.8035vw, 1.375rem);
  margin-top: 0;
  margin-left: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding: var(--spacer);
  margin: var(--spacer) calc(var(--spacer) * 2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  color: var(--brand-primary-color);
  text-decoration: none;
  background-color: #fff;
  border-radius: var(--default-border-radius);
  width: calc(100% - var(--spacer) * 6);
}


/* Breite und Höhe der Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  cursor: pointer;
  border-radius: 8px;
}

/* Hintergrundfarbe der Scrollbar */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  cursor: pointer;
}

/* Farbe des Scrollbalkens */
::-webkit-scrollbar-thumb {
  background-color: var(--brand-primary-color);
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid rgba(255,255,255,0.2);
}

/* Beim Hover-Effekt */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--brand-primary-color);
  cursor: pointer;
}
